import request from '@/utils/request';
/** 列表 */
export function listPagePhone(data) {
    return request({
        url: '/phone/listPagePhone',//请求路径
        method: 'get',
        params: data
    });
}
/** 新增联系人 */
export function addPhone(data) {
    return request({
        url: '/phone/addPhone',//请求路径
        method: 'post',
        data
    });
}
/** 编辑联系人 */
export function modifyPhone(data) {
    return request({
        url: '/phone/modifyPhone',//请求路径
        method: 'post',
        data
    });
}
/** 删除联系人 */
export function deletePhone(data) {
    return request({
        url: '/phone/deletePhone',//请求路径
        method: 'post',
        data
    });
}
/* 获取用户信息 */
export function getCurrentUser(data) {
    return request({
        url: '/rbac/user/getCurrentUser',
        method: 'get',
        params: data
    })
}