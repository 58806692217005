<template>
  <div class="concat-content">
        <van-form @submit.once="onSubmitAdd">
            <van-field
                v-model="editingContact.name"
                label="用户名"
                class="title-label"
                required
            />
            <van-field
                v-model="editingContact.name"
                name="name"
                label=""
                class="title-input"
                placeholder="请填写用户名"
                :rules="[{ required: true}]"
            />
            <van-field
                v-model="editingContact.name"
                label="手机号码"
                class="title-label"
                required
            />
            <van-field
                v-model="editingContact.phone"
                name="phone"
                label=""
                class="title-input"
                placeholder="请输入电话"
                :rules="[{pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/ }]"
            />
        <div class="bottom-item">
            <van-button round block type="info" native-type="submit">保存</van-button>
        </div>
        </van-form>
    </div>
</template>

<script>
import {addPhone, modifyPhone} from '@/api/addressBook';
export default {
    data() {
        return {
            editingContact: {
                name: '',
                phone: '',
                id: ''
            },
            isAdd: true,//是否是新增
        }
    },
    methods: {
        onSubmitAdd(val) {
            if (this.isAdd) {
                addPhone(val).then(res => {
                    if (res.code == 200) {
                        this.$notify({ type: 'success', message: '新增成功' });
                        this.$router.push({name: 'addressBook'})
                    }
                }) 
            } else {
                 modifyPhone(this.editingContact).then(res => {
                    if (res.code == 200) {
                        this.$notify({ type: 'success', message: '编辑成功' });
                        this.$router.push({name: 'addressBook'})
                    }
                }) 
            }
        }
    },
     beforeRouteEnter(to, from, next) {
        if (to.params.id) {
            next(vm => {
                vm.editingContact = to.params;
                document.title = '编辑联系人';
                vm.isAdd = false;
            })
        } else {
            next(vm => {
                vm.editingContact = to.params;
                vm.isAdd = true;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .concat-content {
        width: 347px;
        height: 100%;
        margin: 0 auto;
        padding: 14px;
        background: #fff;
        .bottom-item {
            position: fixed;
            bottom: 75px;
            left: 14px;
            width: 347px;
            height: 43px;
            .van-button--block {
                display: inline-block;
                width: 347px;
                height: 43px;
                background-color: #4499FF;
            }
            .van-button--round {
                border-radius: 4px;
            }
        }
    }

</style>
<style lang="scss">
    .title-label {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin-top: 1px;
        .van-cell__value  {
            display: none;
        }
    }
    .title-input  { 
        height: 50px;
        box-shadow: 0px 0px 8px 0px rgba(205,205,205,0.5);
        border-radius: 4px;
        background: #fff;
    }
</style>